<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_user") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateUser"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estate = computed(() => store.state.estate.estate);
    const user = computed(() => store.getters["user/getUser"]);
    const roles = computed(() => store.state.role.roles);

    const personnelRoles = computed(() => {
      return roles.value.filter((role) => role.label.includes("manager"));
    });

    const estateRoles = computed(() => {
      return roles.value.filter(
        (role) =>
          !role.label.includes("admin") &&
          !role.label.includes("manager") &&
          !role.label.includes("partner")
      );
    });

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const formElements = ref(null);

    const setFormElements = () => {
      if (route.path.includes("personnel")) {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.firstName,
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.lastName,
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "email",
            label: "email",
            rules: ["isRequired", "isEmail"],
            type: "text",
            value: user.value.email,
            variable: "username",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "role-select",
            label: "role",
            optionLabel: ["label"],
            options: personnelRoles.value,
            rules: ["isRequired"],
            translatableLabel: "roles",
            value: user.value.roleId,
            variable: "roleId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "first-name",
            label: "first_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.firstName,
            variable: "firstName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "last-name",
            label: "last_name",
            rules: ["isRequired"],
            type: "text",
            value: user.value.lastName,
            variable: "lastName",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "email",
            label: "email",
            rules: ["isRequired", "isEmail"],
            type: "text",
            value: user.value.email,
            variable: "username",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "apartment",
            label: "apartment",
            rules: [],
            type: "text",
            value: user.value.estates[estate.value.id].apartment,
            variable: "apartment",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "role-select",
            label: "role",
            optionLabel: ["label"],
            options: estateRoles.value,
            rules: ["isRequired"],
            translatableLabel: "roles",
            value: user.value.estates[estate.value.id].roleId,
            variable: "roleId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      }
    };

    onMounted(() => {
      store.dispatch("user/getUser", route.params.userId);
      if (
        route.params.estateId &&
        (!estate.value.id || estate.value.id != route.params.estateId)
      ) {
        store.dispatch("estate/getEstate", route.params.estateId);
      }
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      estate,
      formElements,
      t,
      updateUser: (data) => store.dispatch("user/updateUser", data),
      waiting,
    };
  },
};
</script>
